<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view">
            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="success"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="info"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

            <!--            <el-alert-->
            <!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
            <!--                type="warning"-->
            <!--                effect="dark" style="margin-bottom: 20px">-->
            <!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="error"-->
<!--                effect="dark" style="margin-bottom: 20px" >-->
<!--            </el-alert>-->
          </div>


          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img" style="width: 390px">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 390px"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img" style="width: 390px">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 390px"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img" style="width: 390px">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank" tag="a">
                    <div class="p-img" style="width: 390px"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

              </div>
            </div>
          </div>


          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank" tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "sanbajie",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[

        {'index':0,'url':'http://image.yabyy.com/home/sanjiu/1001134.png','id':'1001134','search':''},      //4893	1001134	抗病毒口服液(玻璃瓶)
        {'index':1,'url':'http://image.yabyy.com/home/sanjiu/1002523.png','id':'1002523','search':''},       //5943	1002523	养胃舒颗粒
        {'index':2,'url':'http://image.yabyy.com/home/sanjiu/1024172.png','id':'1024172','search':''},     //16411	1024172	蒲地蓝消炎片

        {'index':3,'url':'http://image.yabyy.com/home/sanjiu/1001702.png','id':'1001702','search':''},      //5281	1001702	感冒灵胶囊
        {'index':4,'url':'http://image.yabyy.com/home/sanjiu/1002188.png','id':'1002188','search':''},     //5684	1002188	小儿氨酚黄那敏颗粒
        {'index':5,'url':'http://image.yabyy.com/home/sanjiu/1005236.png','id':'1005236','search':''},      //8093	1005236	强力枇杷露
        {'index':6,'url':'http://image.yabyy.com/home/sanjiu/1005695.png','id':'1005695','search':''},      //8364	1005695	复方感冒灵颗粒

        {'index':7,'url':'http://image.yabyy.com/home/sanjiu/1008649.png','id':'1008649','search':''},      //10217	1008649	三九胃泰颗粒
        {'index':8,'url':'http://image.yabyy.com/home/sanjiu/1012572.png','id':'1012572','search':''},      //12372	1012572	正天丸
        {'index':9,'url':'http://image.yabyy.com/home/sanjiu/1013825.png','id':'1013825','search':''},      //12963	1013825	复方醋酸地塞米松乳膏
      ]
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
